import './components/alpine'
import './components/lazyLoading'
import * as components from './components'

// ACC namespace for global configs
const ACC = window.ACC || {
    vars: {},
    methods: {
        initializeComponents: () => {
            const componentNodes = Array.from(document.querySelectorAll('[data-component]')) || []
            componentNodes.forEach(node => {
                const componentName = node.getAttribute('data-component')
                let props = node.getAttribute('data-props')
                if (props) props = JSON.parse(props)

                if (!!components[componentName]) {
                    /* eslint-disable no-new */
                    new components[componentName](node, props)
                }
            })
        },
    },
}

// MUI
window.mui = function () {
    let fired = false;
    const kickTrigger = function () {
        if(fired) return;

        fired = true;

        const event = new Event('muiTriggered');
        document.dispatchEvent(event);
    };

    document.addEventListener('focus', kickTrigger, {once: true, passive: true});
    document.addEventListener('mousemove', kickTrigger, {once: true, passive: true});
    document.addEventListener('scroll', kickTrigger, {once: true, passive: true});
    document.addEventListener('touchstart', kickTrigger, {once: true, passive: true});
}

// Initialize MUI
window.mui();

// load/initialize complex js-components
document.addEventListener('muiTriggered', () => {
    ACC.methods.initializeComponents()
})
