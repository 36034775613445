import lozad from 'lozad'
import { isEditMode } from '../utils';

window.imageLoaded = function (el) {
    const lazyLoadWrap = el.closest('.lazy-load-wrap');
    const placeholder = lazyLoadWrap ? lazyLoadWrap.querySelector('.lazy-load-placeholder') : null

    if (lazyLoadWrap) {
        if (lazyLoadWrap.getAttribute('data-lazy-remove')) lazyLoadWrap.classList.remove(...lazyLoadWrap.getAttribute('data-lazy-remove').split(' '))
        if (lazyLoadWrap.getAttribute('data-lazy-add')) lazyLoadWrap.classList.add(...lazyLoadWrap.getAttribute('data-lazy-add').split(' '))
    }

    if (placeholder) {
        if (placeholder.getAttribute('data-lazy-remove')) placeholder.classList.remove(...placeholder.getAttribute('data-lazy-remove').split(' '))

        if (placeholder.getAttribute('data-lazy-add')) {
            placeholder.classList.add(...placeholder.getAttribute('data-lazy-add').split(' '))
        } else {
            if (placeholder.getAttribute('class').indexOf('transition-') === -1) placeholder.classList.add('transition-all')
            if (placeholder.getAttribute('class').indexOf('duration-') === -1) placeholder.classList.add('duration-500')
            if (placeholder.getAttribute('class').indexOf('opacity-') === -1) placeholder.classList.add('opacity-0')
            placeholder.classList.add('invisible')
        }
    }
}

if (isEditMode()) {
    try {
        setTimeout(() => {
            console.log('Load images in edit mode')
            document.querySelectorAll('img.lozad').forEach((el) => {
                const sources = el.closest('picture').querySelectorAll('[data-srcset]')

                sources.forEach((child) => {
                    if(child.getAttribute('data-src')) {
                        child.setAttribute('src', child.getAttribute('data-src'))
                        child.removeAttribute('data-src')
                    }

                    if(child.getAttribute('data-srcset')) {
                        child.setAttribute('srcset', child.getAttribute('data-srcset'))
                        child.removeAttribute('data-srcset')
                    }
                })

                if(el.getAttribute('data-src')) {
                    el.setAttribute('src', el.getAttribute('data-src'))
                    el.removeAttribute('data-src')
                }

                if(el.getAttribute('data-srcset')) {
                    el.setAttribute('srcset', el.getAttribute('data-srcset'))
                    el.removeAttribute('data-srcset')
                }

                window.imageLoaded(el)
            })
        }, 1000)
    } catch (e) {
        console.log(e)
    }
} else {
    window.lozad = lozad;

    window.observer = window.lozad('img.lozad', {
        threshold: 0.1,
        rootMargin: '150% 150% 150% 150%',
        enableAutoReload: true,
        load: function (el) {
            el.closest('picture').querySelectorAll('[data-srcset]').forEach((child) => {
                child.setAttribute('srcset', child.getAttribute('data-srcset'))
                child.removeAttribute('data-srcset')
            })
        },
        loaded: function (el) {
            window.imageLoaded(el)
        }
    });

    window.observer.observe();
}