import Cookies from 'js-cookie'

let currentCookies = null

const getCookies = () => {
  // if cookies set already, return. if cookies set and empty, return false.
  if (currentCookies && Object.entries(currentCookies).length > 0) return currentCookies
  if (currentCookies && Object.entries(currentCookies).length === 0) return false

  currentCookies = JSON.parse(Cookies.get('acccc') || '{}')

  return Object.entries(currentCookies).length > 0 ? currentCookies : false
}

const setCookies = (cookies) => {
  currentCookies = cookies

  Cookies.set('acccc', JSON.stringify(cookies), {
    path: '/',
    expires: 30, // in days
    sameSite: true,
  })
}

export default {}
export {
  getCookies,
  setCookies,
}
