export function formatPrice(price) {
    if (!price) return null;

    return '€ ' + numberFormat(price, 2, ',', '.').replace(',00', ',-');

    function numberFormat(number, decimals, decPoint, thousandsSep) {
        decimals = typeof decimals !== 'undefined' ? decimals : 2;
        decPoint = typeof decPoint !== 'undefined' ? decPoint : '.';
        thousandsSep = typeof thousandsSep !== 'undefined' ? thousandsSep : ',';

        var sign = number < 0 ? '-' : '';
        var i = parseInt(number = Math.abs(+number || 0).toFixed(decimals), 10) + '';
        var j = i.length > 3 ? i.length % 3 : 0;

        return sign + (j ? i.substr(0, j) + thousandsSep : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandsSep) + (decimals ? decPoint + Math.abs(number - i).toFixed(decimals).slice(2) : '');
    }
}